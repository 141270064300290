import React from "react";

const index = () => {
  return (
    <div className="leistungen-main-container">
      <section className="section-1-container">
        <h1>Our Services</h1>
      </section>
    </div>
  );
};

export default index;
