const homeSlider = [
  {
    id: 1,
    slideClassName: "image1",
  },
  {
    id: 2,
    slideClassName: "image2",
  },
  {
    id: 3,
    slideClassName: "image3",
  },
];
export default homeSlider;
