export const aboutSlider = [
  {
    id: 1,
    slideClassName: "waiting1",
  },
  {
    id: 2,
    slideClassName: "waiting2",
  },
  {
    id: 3,
    slideClassName: "waiting3",
  },
  {
    id: 4,
    slideClassName: "zahnputz",
  },
  {
    id: 5,
    slideClassName: "rooms",
  },
  {
    id: 6,
    slideClassName: "chairs",
  },
  {
    id: 7,
    slideClassName: "image-chair",
  },
];
